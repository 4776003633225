/* TITLE */
.form-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-title {
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #40435b;
}

.form-subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
}

.form-subtitle-text {
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #6b6f93;
}

.form-web-credential {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* FORM */

.form-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 32px;
}

.form-error {
    display: flex;
    justify-content: center;
    /* margin: 16px 0; */
}

.form-error-text {
    color: #e22e2e;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.form-interactive-area-admin {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    max-height: 233px;
    width: 100%;
    padding-right: 10px;
}

.form-interactive-area-client {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    max-height: 233px;
    box-sizing: content-box;
    width: 100%;
    padding-right: 10px;
}

.form-interactive-area-large {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: auto;
}

/* Estilo para Chrome, Edge y Safari */
.form-interactive-area-admin::-webkit-scrollbar,
.form-interactive-area-client::-webkit-scrollbar {
    width: 4px;
}

.form-interactive-area-admin::-webkit-scrollbar-track,
.form-interactive-area-client::-webkit-scrollbar-track {
    background-color: #edf2ff;
    border-radius: 8px;
}

.form-interactive-area-admin::-webkit-scrollbar-thumb,
.form-interactive-area-client::-webkit-scrollbar-thumb {
    background-color: #6b6f93;
    border-radius: 8px;
}

.form-interactive-area-admin::-webkit-scrollbar-thumb:hover,
.form-interactive-area-client::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}
