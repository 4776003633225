.container-form {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.input-picker-selector {
    width: 100%;
}

.input-picker-selector div[role='combobox'] {
    border: 1px solid;
}

.input-picker-selector div[role='combobox']:focus, .input-picker-selector div[role='combobox']:active, .input-picker-selector div[role='combobox']:hover {
    border-color: 1px solid !important;
}

.container-label {
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.container-form-panel {
    width: 600px;
}

.container-button-send{
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}
.success-button-active {
    display: flex !important;
    justify-content: center !important;
    background-color: #07ad4b !important;
    color: #fff !important;
    padding: 10px 24px !important;
    width: 30%;
}
.button{
    width: 30%;
}

.text-amount{
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 8px;
}
.container-success {
    display: flex; /* Flexbox para organizar los elementos */
    flex-direction: column; /* Alinea los elementos en columna */
    justify-content: center; /* Centra verticalmente */
    align-items: center; /* Centra horizontalmente */
    text-align: center; /* Alinea el texto en el centro */
    margin: 36px 0; /* Espaciado superior e inferior (opcional) */
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.success-button-active {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important; /* Centra el contenido verticalmente */
    background-color: #07ad4b !important;
    color: #fff !important;
    border-radius: 50% !important; /* Asegura que el botón sea redondo */
    width: 50px; /* Ajusta el tamaño según lo que necesites */
    height: 50px; /* Igual al ancho para mantener la forma circular */
    border: none; /* Opcional: elimina bordes si no los quieres */
    margin-bottom: 10px; /* Espaciado entre el icono y el texto */
}
.container-plan-detail {
    margin-bottom: 32px;
    text-align: left; /* Asegura que el texto esté alineado a la izquierda */
}


.text-information{
    font-size: 24px;
    line-height: 35px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #333333;
    opacity: 1;
    text-align: left;
    letter-spacing: 0px;
    margin-bottom:8px;
}