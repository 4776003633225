.container {
    background: var(--Secondary-25, #f0f5ff);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panel {
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    width: 466px;
}

.panel > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    text-align: center;
    width: 466px;
    padding: 48px;
}

.custom-width {
    width: 464px;
}

.custom-width > div {
    width: 464px;
}

/* contactanos */
.contact-us {
    text-decoration: underline;
    margin: 0px 8px;
    cursor: pointer;
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    padding: 8px 8px 16px 8px;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

/* link */
.redirect-login {
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
