.sidenav-footer {
  margin: 24px 0;
}
.wrapper-items-settings {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 16px 0px 32px 0px;
  cursor: pointer;
}
.tooltip-component {
  background-color: #0b2033 !important;
  color: #fff;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  left: 74px !important;
}
.tooltip-component::after {
  border-right-color: #0b2033 !important;
}
.container-settins-icons {
  position: relative;
}
.content-icon-settings {
  color: #fff;
}
.microphone-icon {
  background-color: #dcedff;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #0171e6 !important;
}
.circle-badge {
  background-color: #07ad4b;
  border-radius: 100px;
  width: 16px;
  height: 16px;

  position: absolute;
  bottom: 2px;
  right: 2px;
}