.container-navbar {
    height: 56px;
    position: relative !important;
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    background: var(--Primary-600, #0171e6);
}

.wrapper-navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: var(--Primary-600, #0171e6);
    height: 56px;
}

.container-cols {
    width: 100%;
}

.container-row {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.container-commands {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.wrapper-cart-blur {
    background-color: rgba(64, 67, 91, 0.9);
    width: calc(100vw - 72px);
    height: 56px;
    position: absolute;
    z-index: 9999;
}
.drawer-fade-in {
    /* ANIMATION */
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: slideInRight;
    display: block;
}

.drawer-fade-out {
    /* ANIMATION */
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: slideOutRight;
}

.col-header {
    display: flex; 
    justify-content: center;
    align-items: flex-end;
    margin: 0px 16px;
  }

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes slideOutRight {
    0% {
        opacity: 1;
        transform: none;
    }

    100% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
        display: none;
        visibility: hidden;
    }
}
