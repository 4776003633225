.container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background: var(--Neutral-White, #fff);
    height: 100vh;
    width: 100%;
}
