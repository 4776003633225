.autocomplete-close-icon-button {
  position: absolute;
  top: 8px;
  right: 24px;
  z-index: 2100;
}

.autocomplete-icon-button-index {
  background-color: transparent !important;
}

.autocomplete-icon-button-index:hover,
.autocomplete-icon-button-index:active,
.autocomplete-icon-button-index:focus {
  background-color: transparent !important;
}