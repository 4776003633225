.panel-container {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  width: 483px;
}

.panel-container-step4 {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  width: 467px;
  height: 193px;
}
.panel-container-pos {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  width: 483px;
  position: absolute;
  top: -140px;
}

.button-back {
  color: #6b6f93;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  padding: 8px;
}

.button-foward {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  padding: 8px;
  color: #40435b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 16px;
}
.button-end {
  background-color: #1a66fc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 8px 16px;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  margin-left: 16px;
}
.icon-arrow {
  margin-left: 12px;
  height: 16px;
  width: 16px;
  color: #1a66fc;
}

.icon-arrow-end {
  margin-left: 12px;
  height: 16px;
  width: 16px;
  color: white;
}
.line {
  background-color: #acaec4;
  width: 435px;
  height: 0.5px;
}

.content {
  color: #6B6F93;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  padding-bottom: 16px;
}

.title {
  color: #40435b;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;
  padding-bottom: 8px;
}

.footer-badge {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer {
  display: flex;
  padding-top: 16px;
  justify-content: space-between;
}

.step {
  color: #6b6f93;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-right: 16px;
}

.close-button {
  color: #e0e1e9;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background-color: transparent;
  border: none;
  margin-top: 24px;
  width: 100%;

}

.close-button-step3 {
  color: #e0e1e9;
  text-align: center;
  font-size: 16px;
  position: relative;
  top:-24px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background-color: transparent;
  border: none;
  margin-top: 24px;
  width: 100%;

}