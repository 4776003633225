/* --------------------------LAYOUT GRID SYSTEM -------------------------- */
.layout-grid-container {
    padding: 16px 19px 0px 19px;
    display: grid;
    grid-template-rows: 32px auto;
    max-width: 100%;
    overflow-y: auto; /* Añade scroll vertical */
    overflow-x: hidden;
    /* max-height: calc(100vh - 55.5px); .5px por border-top */
    /* min-height: calc(100vh - 55.5px); .5px por border-top */
    text-align: center;
}

/* ----------------- LAYOUT WITH FOOTER NOT EXPANDED -------------- */
.layout-table-with-recommendations {
    z-index: 1; /*zindex because filters zindex*/
    grid-row: 2;
    display: grid;
    grid-template-rows: 16px 54px 90px auto 50px 80px;
    grid-template-columns: 1fr 1fr;
    transition: grid-template-rows 0.8s ease; /* add a smooth transition */
    position: relative;
}

.layout-table-without-recommendations {
    z-index: 1; /*zindex because filters zindex*/
    grid-row: 2;
    display: grid;
    grid-template-rows: 16px 54px auto 50px 80px;
    grid-template-columns: 1fr 1fr;
    transition: grid-template-rows 0.8s ease; /* add a smooth transition */
    position: relative;
}

/* --------------- LAYOUT WITH FOOTER EXPANDED ---------------- */
.layout-table-with-recommendations-expanded-footer {
    z-index: 1; /*zindex because filters zindex*/
    display: grid;
    grid-template-rows: 16px 54px 90px auto 94px 80px;
    grid-template-columns: 1fr;
    transition: grid-template-rows 0.8s ease; /* add a smooth transition */
    position: relative;
}

.layout-table-without-recommendations-expanded-footer {
    z-index: 1; /*zindex because filters zindex*/
    display: grid;
    grid-template-rows: 16px 54px auto 94px 80px;
    grid-template-columns: 1fr;
    transition: grid-template-rows 0.8s ease; /* add a smooth transition */
    position: relative;
}

/* ------------------------------------------ REDISEÑO POSICIONAMIENTO DE HEADER SCROLLEABLE ------------------------------------------ */

/* ----------------- Nueva posición del header ----------------- */
.header {
    grid-row: 1;
}
/* ----------------- Nuevo posicionamiento toolbar ----------------- */
.toolbar {
    position: sticky;
    z-index: 999;
    top: 56px;
}

/* --------- REDISEÑO ----------- */
.layout-grid-container-v2 {
    display: grid;
    /* grid-template-rows: 56px 64px auto; */
    max-width: 100%;
    overflow-y: auto; /* Añade scroll vertical */
    overflow-x: hidden;
    text-align: center;
    padding: 0px;
}

/* ----------------- LAYOUT WITH FOOTER NOT EXPANDED -------------- */
.layout-table-with-recommendations-v2 {
    z-index: 1; /*zindex because filters zindex*/
    grid-row: 3;
    display: grid;
    grid-template-rows: 54px 90px auto 50px 80px;
    grid-template-columns: 1fr 1fr;
    transition: grid-template-rows 0.8s ease; /* add a smooth transition */
    position: relative;
}

.layout-table-without-recommendations-v2 {
    z-index: 1; /*zindex because filters zindex*/
    /* grid-row: 3; */
    display: grid;
    grid-template-rows: 54px auto 50px 80px;
    grid-template-columns: 1fr 1fr;
    transition: grid-template-rows 0.8s ease; /* add a smooth transition */
    position: relative;
}

/* --------------- LAYOUT WITH FOOTER EXPANDED ---------------- */
.layout-table-with-recommendations-expanded-footer-v2 {
    z-index: 1; /*zindex because filters zindex*/
    display: grid;
    grid-template-rows: 54px 90px auto 94px 80px;
    grid-template-columns: 1fr;
    transition: grid-template-rows 0.8s ease; /* add a smooth transition */
    position: relative;
}

.layout-table-without-recommendations-expanded-footer-v2 {
    z-index: 1; /*zindex because filters zindex*/
    display: grid;
    grid-template-rows: 54px auto 94px 80px;
    grid-template-columns: 1fr;
    transition: grid-template-rows 0.8s ease; /* add a smooth transition */
    position: relative;
}
