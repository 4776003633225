/* container form */
.form-container {
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}

/* title description */
.box-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 16px;
}

.title {
    color: var(--Secondary-800, #0b2033);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0px;
}

.subtitle {
    color: var(--Secondary-800, #0b2033);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0px;
}

/* form gral */
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}

/* all input */
.input {
    width: 100%;
    margin: 0px !important;
}

/* quitar error text */
.input > div > div:last-child {
    display: none;
}

/* aplicar padding y gap personalizado entre el startIcon y el input */
.input > div > div {
    padding: 10px 16px;
    gap: 8px;
    height: 48px;
}

/* aplicar altura nueva al startIcon e input */
.input > div > div * {
    height: 24px;
}

/* remover padding de startIcon */
.input > div > div > span {
    padding: 0px;
}

/* remover padding de input */
.input > div > div > div > input {
    height: 100%;
    padding: 0px;
}

/* quitar fondo azul porque sino le aplica solo al texto (input) */
.input > div > div > div > input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    background-color: #fff !important;
}

/* quitar fondo azul porque sino le aplica solo al texto (input) */
.input > div > div > div > input:autofill {
    background-color: #fff !important;
}

/* aplicar overflow hidden al input para evitar desborde y perdida de border */
.input > div > div > div {
    overflow: hidden;
}