.container-onboarding{
    border-radius: 8px;
    background: var(--Primary-600, #0171e6);
    width: 365px;
  
    z-index: 99999;
    position: absolute;
    left:83px;
    top:76%;
    padding: 24px;
  }
  
  .container-onboarding::before {
    content: '';
    position: absolute;
    top: 10%; /* Centra verticalmente */
    left: -20px; /* Ajusta según el tamaño */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #0171e6  transparent transparent; /* Define los colores de los bordes */
  }
  
.content-badge {
    border-radius: 100px;
    background: var(--Neutral-White, #fff);
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 180% */

    margin-right: 8px;
}

.content-badge-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title-popover-analytics {
    color: var(--Neutral-White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Heading/Heading 2 Semibold */
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}

.subtitle-popover-analytics {
    color: var(--Neutral-White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body/Body 1/Regular */
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    margin-top: 8px;
}
.container-button-analytics {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}