
.container-without-search {
  display: flex;
  justify-content: center;
}

.without-search-text {
  text-align: center;
}
.title-without-searching {
  color: #40435b;
  font-family: SourceSansProSemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.subtitle-without-searching {
  color: #40435b;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.container-images {
  display: flex;
  justify-content: center;

  position: relative;

  margin: 24px;
}
.image-vector {
  
  display: flex;
  justify-content: center;
}
.vector-img{
  width: 125px;
  height: 154px;
}
.framer-icon{
  width: 157px;
  height: 113px;

}
.framer-icon {

  position: absolute;
  bottom: 7px;

  display:flex;
  justify-content: center !important;
}
.container{
  height: 300px;
  margin: 16px 0px;
}