/* box right */
.container-content {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 110px;
    position: relative;
}

/* box form */
.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;
}

/* title form */
.title-form {
    color: var(--Secondary-800, #0b2033);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
}

/* text error form */
.error-form {
    color: var(--Semantic-Error-600, #e22e2e);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    width: 100%;
    text-align: center;
}

/* form gral */
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}

/* all input */
.input {
    width: 100%;
    margin: 0px !important;
}

/* quitar error text */
.input > div > div:last-child {
    display: none;
}

/* aplicar padding y gap personalizado entre el startIcon y el input */
.input > div > div {
    padding: 10px 16px;
    gap: 8px;
    height: 48px;
}

/* aplicar altura nueva al startIcon e input */
.input > div > div * {
    height: 24px;
}

/* remover padding de startIcon */
.input > div > div > span {
    padding: 0px;
}

/* remover padding de input */
.input > div > div > div > input {
    height: 100%;
    padding: 0px;
}

.input > div > div > div > input::placeholder {
    color: #ACAEC4;
}

/* quitar fondo azul porque sino le aplica solo al texto (input) */
.input > div > div > div > input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    background-color: #fff !important;
}

/* quitar fondo azul porque sino le aplica solo al texto (input) */
.input > div > div > div > input:autofill {
    background-color: #fff !important;
}

/* aplicar overflow hidden al input para evitar desborde y perdida de border */
.input > div > div > div {
    overflow: hidden;
}

/* reset password */
.box-password {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.reset-password {
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: end;
    text-decoration: underline;
}

/* contactanos */
.contact-us {
    text-decoration: underline;
    margin: 0px 8px;
    cursor: pointer;
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    padding: 8px 8px 16px 8px;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
