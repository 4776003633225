.modal-onBoarding {
  position: absolute;
  bottom: 40px;
  right: 36px;
  display: flex;
  width: 312px;
  min-height: 208px;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 4px 6px -2px rgba(64, 67, 91, 0.05),
    0px 10px 15px -3px rgba(64, 67, 91, 0.1);
  border-radius: 8px;
  background: #fff;
  z-index:1000;
}

.icon {
  height: 24px;
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #40435b;
  margin-top: 24px;
  margin-bottom: 0px;
}

.subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #6b6f93;
  margin-top: 8px;
}

.button {
  border-radius: 8px;
  background: #0171e6;
  display: flex;
  width: 228px;
  margin-top: 24px;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: white;
  transition: all 0.2s linear;
}

.button:hover{
  background-color: #0062C8;
}

.button:active{
  background-color: #0056B0;
}

.arrow {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.icon-close{
  color:#40435B;
  cursor: pointer;
}

.icon-close-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin:0px
}