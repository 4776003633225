.msg-terms-conditions {
    color: var(--Neutral-800, var(--800, #40435b));
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.as-link {
    cursor: pointer;
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
