.container-input {
  position: relative;
}

.autocomplete{
  border-radius: 0px !important;
}

.input-picker {
  width: 100%;
}

.input-picker div div input {
  padding: 8px 40px !important;
}

.input-picker div div div span {
  padding-left: 25px !important;
}

.icon-search {
  position: absolute;
  bottom: 8px;
  left: 13px;
  z-index: 6;

  display: flex;
  align-items: center;
}

.icon {
  color: #0171E6;
}

.loading-icon {
  color: #0171E6;
  font-size: 24px;
}

.container-loading {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* AUTOCOMPLETE STYLES */
.autocomplete-icon-search {
  position: absolute;
  top: 0;
  left: 13px;
  z-index: 6;
  display: flex;
  align-items: center;
}

.container-input > div {
  height: 56px;
}

.container-input > div > input {
  height: 56px;
  padding-left: 40px !important;
  color: #40435B;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;


}
.menu {
  padding: 16px 0px !important;
}