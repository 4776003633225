.container {
    width: 100%;
    min-height: 104px;
    height: 104px;
    padding: 0px 120px;
    gap: 0px;
    border: 0.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* opacity: 0px; */
}
